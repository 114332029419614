import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { useAtom } from 'jotai'
import Layout from 'components/Layout'
import Container from 'components/Container'
import Title from 'components/Title'
import innerHTML from '../utils/innerHTML'
import { metaTitle } from '../utils/page-meta-utils'
import { alternateLanguagesState } from '../atoms/languageSwitcher'

const SimplePage = ({ data }) => {
  if (!data) return null
  const document = data.prismicSimplePage

  const [alternateLanguages, setAlternateLanguages] = useAtom(alternateLanguagesState)

  useEffect(() => {
    setAlternateLanguages(document.alternate_languages)
  }, [])

  return (
    <Layout pageName='SimplePage'>
      <Helmet>
        <title>{ document.data.meta_title || metaTitle(document.data.title.text) }</title>
        <meta name='description' content={ document.data.meta_description || '' } />
      </Helmet>
      <Container size='medium' className='SimplePage-container' tag='section'>
        <div className='SimplePage-content'>
          <Title tag='h2'>{ document.data.title.text }</Title>
          <div className='SimplePage-text Wysiwyg' { ...innerHTML(document.data.content.html) } />
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query SimplePageQuery($uid: String, $lang: String) {
    prismicSimplePage(uid: { eq: $uid }, lang: { eq: $lang }) {
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        meta_title
        meta_description
        title {
          text
        }
        content {
          html
        }
      }
    }
  }
`

export default SimplePage
